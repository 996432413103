import {Circle, Marker} from "react-leaflet";
import {useLastUserLocations} from "../contexts/LastUserLocationContext";
import 'leaflet/dist/leaflet.css';
import {useMemo, useRef} from "react";
import {divIcon, Marker as LeafletMarker} from "leaflet";
import {tcsRed} from "../mui/Theme";

const fillRedOptions = {fillColor: tcsRed};

const redPoint = divIcon({
    html: `<div class="leaflet-marker-red-circle"></div>`
});

export default function DraggableCircle() {
    const {circleCenter, circleRadius, setCircleCenter} = useLastUserLocations();
    const markerRef = useRef<LeafletMarker | null>(null);

    const eventHandlers = useMemo(
        () => ({
            drag() {
                const marker = markerRef.current;
                if (marker != null) {
                    setCircleCenter(marker.getLatLng());
                }
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <>
            {circleCenter &&
                <>
                    <Marker ref={markerRef} position={[circleCenter?.lat, circleCenter?.lng]} draggable={true}
                            eventHandlers={eventHandlers} icon={redPoint}/>
                    {circleRadius &&
                        <Circle center={[circleCenter?.lat, circleCenter?.lng]} radius={circleRadius} pathOptions={fillRedOptions}
                                stroke={false}/>
                    }
                </>
            }
        </>
    )
}