import {DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue} from "firebase/firestore";

const DateConverter = {
    toFirestore(Date: WithFieldValue<Date>): DocumentData {
        return {Date};
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Date {
        const data = snapshot.data(options);
        return DateConverter.fromData(data);
    },
    fromData(data: any): Date {
        return data.toDate()
    }
}

export default DateConverter