import {
    OAuthProvider,
    onAuthStateChanged,
    signInWithRedirect,
    signOut
} from 'firebase/auth';
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {auth} from '../firebase/config/config';
import DialogLoader from "../components/dialog/DialogLoader";

interface User {
    uid: string;
    email: string | null;
    displayName: string | null;
}

interface AuthContextInterface {
    signin(): void;
    logout(): void;
    user: User | null;
}

const authDefaultContext = createContext<AuthContextInterface>({
    signin: () => {
        return
    },
    logout: () => {
        return
    },
    user: null
});

export const useAuth = () => useContext(authDefaultContext);

export const AuthContextProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, (userInfo) => {
            if (userInfo) {
                setUser({
                    uid: userInfo.uid,
                    email: userInfo.email,
                    displayName: userInfo.displayName
                });
            }
            setLoading(false);
        });
    }, [])

    const signin = async () => {
        const tcsProvider = new OAuthProvider(process.env.REACT_APP_OAUTH_PROVIDER!);
        signInWithRedirect(auth, tcsProvider);
    }

    const logout = async () => {
        await signOut(auth).then(() => {
            setUser(null);
        });
    }

    const authProvider: AuthContextInterface = {
        user: user,
        signin: signin,
        logout: logout
    }

    return (
        <authDefaultContext.Provider value={authProvider}>
            {loading ?
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/> :
                children
            }
        </authDefaultContext.Provider>
    )
}
