import {MapContainer, Marker, TileLayer, useMapEvents} from 'react-leaflet';
import {LatLngBounds, divIcon, LeafletMouseEvent, LatLng, Icon, BaseIconOptions} from "leaflet";
import {useLastUserLocations} from "../../contexts/LastUserLocationContext";
import {LastUserLocation} from "../../utils/models/LastUserLocation";
import {useEffect, useMemo, useRef, useState} from "react";
import useSupercluster from "use-supercluster";
import {Cluster, DEFAULT_RADIUS_SLIDER, Point, PointOrCluster} from "../../leaflet/map";
import {BBox} from "geojson";
import DraggableCircle from "../DraggableCircle";
import LMapMinimapControl from "./LMapMinimapControl";
import LMapControl from "./LMapControl";
import {useUserLocations} from "../../contexts/UserLocationContext";
import {UserLocation} from "../../utils/models/UserLocation";

const iconMarker = new Icon({
    iconUrl: "/marker-icon.png",
    shadowUrl: "/marker-shadow.png",
    iconRetinaUrl: "/marker-icon-2x.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
} as BaseIconOptions)

const iconMarkerActive = new Icon({
    iconUrl: "/marker-icon-red.png",
    shadowUrl: "/marker-shadow.png",
    iconRetinaUrl: "/marker-icon-2x-red.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
} as BaseIconOptions);

const worldBounds = new LatLngBounds(
    new LatLng(-88, -180),
    new LatLng(88, 180)
);

interface Props {
    clustering: boolean;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export default function LMap({clustering = true}: Props) {
    const {
        lastUserLocationsInView,
        drawCircle,
        circleCenter,
        setCircleCenter,
        zoom,
        setZoom,
        setSelectedLastUserLocationMarker,
        selectedLastUserLocationMarker,
        bound,
        setBound,
        view,
        center,
        setCenter,
        setCircleRadius
    } = useLastUserLocations();

    const {
        userLocationsInView,
        setSelectedUserLocationMarker,
        selectedUserLocationMarker
    } = useUserLocations();

    const mapRef = useRef<any>(null);

    const [selectedClusterId, setSelectedClusterId] = useState<number | undefined>(undefined);

    let radius = 1;
    if (zoom > 0 && zoom <= 10) {
        radius = 300;
    } else if (zoom > 10 && zoom <= 13) {
        radius = 240;
    } else if (zoom > 13) {
        radius = 130;
    }

    const selectedClusterIcon = (poc: PointOrCluster, size: number) => {
        return divIcon({
            html: `<div class="leaflet-cluster-marker-active" style="width: ${size}px; height: ${size}px;">${poc.pointCount}</div>`
        })
    }

    const clusterIcon = (poc: PointOrCluster, size: number) => {
        return divIcon({
            html: `<div class="leaflet-cluster-marker" style="width: ${size}px; height: ${size}px;">${poc.pointCount}</div>`
        })
    }

    const points: Point[] = lastUserLocationsInView.map((lastUserLocation: LastUserLocation) => {
        const point: Point = {
            properties: {
                lastUserLocation: lastUserLocation
            },
            geometry: {
                coordinates: [
                    lastUserLocation.location.lng,
                    lastUserLocation.location.lat
                ]
            }
        }
        return point;
    });

    const {clusters: rawPointsOrClusters, supercluster: cluster} = useSupercluster({
        points: points,
        bounds: [bound?.getSouthWest().lng, bound?.getSouthWest().lat, bound?.getNorthEast().lng, bound?.getNorthEast().lat] as BBox,
        zoom: zoom,
        options: {radius: radius, minZoom: 0, maxZoom: 14}
    });

    const pointOrClusters: PointOrCluster[] = rawPointsOrClusters.map((rawPointOrCluster: any) => {
        const isCluster = rawPointOrCluster.properties.cluster === true;
        const pointCount = rawPointOrCluster.properties.point_count ?? 0;
        const cluster = isCluster ? rawPointOrCluster as Cluster : undefined;
        const point = isCluster ? undefined : rawPointOrCluster as Point;

        let lastUserLocation: LastUserLocation | undefined = undefined;
        lastUserLocation = point?.properties.lastUserLocation;

        const pointOrCluster: PointOrCluster = {
            isCluster: isCluster,
            pointCount: pointCount,
            cluster: cluster,
            point: point,
            lastUserLocation: lastUserLocation
        }
        return pointOrCluster;
    });

    const zoomIn = () => {
        if (mapRef.current) {
            mapRef.current.zoomIn(1);
        }
    };

    const zoomOut = () => {
        if (mapRef.current) {
            mapRef.current.zoomOut(1);
        }
    };

    const EventsMap = () => {
        const map = useMapEvents({
            moveend: () => {
                setBound(map.getBounds());
                setZoom(map.getZoom());
                setCenter(map.getCenter());
            },
            click: (e: LeafletMouseEvent) => {
                if (drawCircle && circleCenter === null) {
                    setCircleCenter(e.latlng);
                } else {
                    setSelectedLastUserLocationMarker(undefined);
                    setSelectedUserLocationMarker(undefined);
                }
            }
        });
        return null;
    }

    useEffect(() => {
        const handleClusterSelection = () => {
            setSelectedClusterId(undefined);
            const clusters = pointOrClusters.filter((poc: PointOrCluster) => poc.isCluster);
            for (const clusterItem of clusters) {
                // @ts-ignore
                const markersInCluster = cluster.getLeaves(clusterItem.cluster?.id, 10, 0);
                const lastUserLocationsId = markersInCluster.map((item: any) => item.properties.lastUserLocation.id);

                if (lastUserLocationsId.includes(selectedLastUserLocationMarker?.id)) {
                    setSelectedClusterId(clusterItem.cluster?.id);
                    break;
                }
            }
        };

        if (selectedLastUserLocationMarker !== undefined) {
            handleClusterSelection();
        } else {
            setSelectedClusterId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLastUserLocationMarker, pointOrClusters]);

    useEffect(() => {
        if (mapRef.current && drawCircle && !circleCenter) {
            setCircleCenter(mapRef.current.getCenter());
            setCircleRadius(DEFAULT_RADIUS_SLIDER);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawCircle])

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setView(view?.location, view?.zoom, view?.options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view])

    const handleClusterClick = (poc: PointOrCluster) => {
        if (mapRef.current) {
            // @ts-ignore
            const expansionZoom = Math.min(cluster.getClusterExpansionZoom(poc.cluster?.id), 20);
            mapRef.current.setView({
                lat: poc.cluster!.geometry.coordinates[1],
                lng: poc.cluster!.geometry.coordinates[0]
            }, expansionZoom, {animate: true});
        }
    };

    const handleMarkerClick = (poc: PointOrCluster) => {
        if (mapRef.current) {
            setSelectedLastUserLocationMarker(poc.point!.properties.lastUserLocation);
            mapRef.current.setView({
                lat: poc.point!.geometry.coordinates[1],
                lng: poc.point!.geometry.coordinates[0]
            }, zoom, {animate: true});
        }
    };

    const markers = useMemo(() => pointOrClusters?.map((poc: PointOrCluster) => {
        const [lng, lat] = poc.isCluster ? poc.cluster!.geometry.coordinates : poc.point!.geometry.coordinates;
        if (poc.isCluster) {
            return <Marker
                eventHandlers={{
                    click: () => handleClusterClick(poc)
                }}
                key={poc.cluster!.id}
                position={[lat, lng]}
                icon={poc.cluster!.id === selectedClusterId ?
                    selectedClusterIcon(poc, 10 + (poc.pointCount / points.length) * 40) :
                    clusterIcon(poc, 10 + (poc.pointCount / points.length) * 40)}/>
        } else {
            return <Marker
                eventHandlers={{
                    click: () => handleMarkerClick(poc)
                }}
                key={poc.point!.properties.lastUserLocation.id}
                position={[lat, lng]}
                {...(selectedLastUserLocationMarker?.id === poc.point!.properties.lastUserLocation.id ? {icon: iconMarkerActive} : {icon: iconMarker})}
            />
        }
        // eslint-disable-next-line
    }), [pointOrClusters, selectedClusterId, selectedLastUserLocationMarker]);

    return (
        <MapContainer
            className={"map-container"}
            minZoom={3}
            maxZoom={14}
            center={[center.lat, center.lng]}
            maxBounds={worldBounds}
            zoom={zoom}
            scrollWheelZoom={true}
            zoomControl={false}
            ref={mapRef}
        >
            <TileLayer
                url={"https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=" + MAPBOX_TOKEN}
                tileSize={512}
                zoomOffset={-1}/>
            <LMapMinimapControl/>
            <DraggableCircle/>
            <LMapControl zoomIn={zoomIn} zoomOut={zoomOut}/>
            <EventsMap/>
            {clustering ?
                <>
                    {markers}
                </> :
                <>
                    {userLocationsInView.map((userLocation: UserLocation) => {
                        return <Marker
                            eventHandlers={{
                                click: () => {
                                    if (mapRef.current) {
                                        setSelectedUserLocationMarker(userLocation);
                                        mapRef.current.setView(
                                            {lat: userLocation.location.lat, lng: userLocation.location.lng},
                                            zoom,
                                            {animate: true}
                                        );
                                    }
                                },
                            }}
                            key={userLocation.id}
                            position={[userLocation.location.lat, userLocation.location.lng]}
                            {...(selectedUserLocationMarker?.id === userLocation.id ? {icon: iconMarkerActive} : {icon: iconMarker})}
                        />
                    })}
                </>
            }

        </MapContainer>
    )
}