import {LastUserLocation} from "../utils/models/LastUserLocation";

export const MIN_RADIUS_SLIDER = 1000;
export const DEFAULT_RADIUS_SLIDER = 100000;
export const MAX_RADIUS_SLIDER = 500000;

export interface Cluster {
    id: number;
    properties: {
        point_count: number
    };
    geometry: {
        coordinates: number[]
    };
}

export interface Point {
    properties: {
        lastUserLocation: LastUserLocation
    };
    geometry: {
        coordinates: number[]
    };
}

export interface PointOrCluster {
    isCluster: boolean;
    cluster?: Cluster;
    point?: Point;
    lastUserLocation?: LastUserLocation;
    pointCount: number;
}
