import {Divider, List, Stack} from "@mui/material";
import NotificationItem from "./item/NotificationItem";
import {Notification} from "../../utils/models/Notification";

interface Props {
    notifications: Notification[];
}

export default function NotificationList({notifications}: Props) {
    return (
        <Stack className={"notifications"} mt={2} sx={{overflowY: "scroll", height: {md: "50vh", xs: "40vh"}}}>
            {notifications.length > 0 &&
                <Divider/>
            }
            <List sx={{paddingTop: 0, paddingBottom: 0}}>
                {notifications.map((notification: Notification, index: number) => {
                    return <NotificationItem
                        key={index}
                        index={index}
                        notification={notification}
                        length={notifications.length}
                    />
                })}
            </List>
            {notifications.length > 0 &&
                <Divider/>
            }
        </Stack>
    )
}