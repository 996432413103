import {createTheme} from '@mui/material/styles';

export const fontFamilyMuseo300 = [
    '"Museo Sans 300"',
    'sans-serif',
].join(',');
export const fontFamilyMuseo700 = [
    '"Museo Sans 700"',
    'sans-serif',
].join(',');

export const tcsRed = "#cd0046";
export const tcsBlueDark = "#005aa0";
export const tcsBeige1 = "#f5f1e9"
export const tcsBeige2 = "#e8e2d5"
export const tcsBeige3 = "#d6cebc"

export const Theme = createTheme({
    palette: {
        primary: {
            main: '#ffeb00',
        },
        secondary: {
            light: '#50afe1',
            main: '#005aa0',
        },
        success: {
            light: '#aac800',
            main: '#69a023'
        },
        error: {
            main: '#cd0046',
            light: '#fcb6b6'
        },
        warning: {
            main: '#f59b00'
        }
    },
    typography: {
        fontSize: 12,
        fontFamily: `${fontFamilyMuseo300}`,
        body1: {
            lineHeight: 1.4,
            fontSize: 12,
        },
        body1Bold: {
            fontFamily: `${fontFamilyMuseo700}`,
            fontWeight: 700,
            lineHeight: 1.4,
            fontSize: 13,
        },
        body2: {
            lineHeight: 1.4,
            fontSize: 10,
        },
        h1: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '46px',
            lineHeight: '46px',
            marginBottom: '20px'
        },
        h2: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '32px',
            marginBottom: '20px'
        },
        h3: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '24px',
        },
        h4: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '18px'
        },
        h5: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '14px'
        },
        h6: {
            fontFamily: fontFamilyMuseo700,
            fontWeight: 700,
            fontSize: '12px'
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: '#999'
                    }
                }
            }
        }
    }
});


