import {Divider, Stack, TablePagination, Typography} from "@mui/material";
import Admin from "../Admin";
import Menu from "../Menu";
import NotificationList from "../list/NotificationList";
import {
    QueryDocumentSnapshot,
    collection,
    endBefore,
    limit,
    limitToLast,
    query,
    Query,
    startAfter,
    getDocs, getCountFromServer, orderBy
} from "@firebase/firestore";
import {firestore} from "../../firebase/config/config";
import NotificationConverter from "../../utils/converters/NotificationConverter";
import {useEffect, useMemo, useState} from "react";
import {Notification} from "../../utils/models/Notification";
import {useTranslation} from "react-i18next";
import ErrorResponseDialog from "../dialog/ErrorResponseDialog";

export default function NotificationDashboard() {
    const {t} = useTranslation();

    const [notifications, setNotifications] = useState<Notification[]>([]);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const [lastVisibleSnapshot, setLastVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();
    const [firstVisibleSnapshot, setFirstVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();

    const [error, setError] = useState<TemplateStringsArray | undefined>();

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        setLastVisibleSnapshot(null);
        setFirstVisibleSnapshot(null);
        try {
            const queryNotifications = getNotificationsQuery(null, null, parseInt(event.target.value));
            await getNotifications(queryNotifications);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const getNotifications = async (query: Query) => {
        try {
            const notificationsSnapshot = await getDocs(query);
            const notifications = notificationsSnapshot.docs.map(notification => notification.data()) as Notification[];
            setNotifications(notifications);
            setLastVisibleSnapshot(notificationsSnapshot.docs[notificationsSnapshot.docs.length - 1]);
            setFirstVisibleSnapshot(notificationsSnapshot.docs[0]);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const onPageChanged = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        try {
            const queryUsers = getNotificationsQuery(
                newPage > page ? lastVisibleSnapshot : null,
                newPage < page ? firstVisibleSnapshot : null
            );

            await getNotifications(queryUsers);
        } catch (error: any) {
            setError(error.message);
        }
    }

    const getNotificationsQuery = (
        startAfterSnapshost: QueryDocumentSnapshot | null | undefined,
        endBeforeSnapshot: QueryDocumentSnapshot | null | undefined,
        perPage?: number
    ): Query => {
        let queryNotifications;
        if (startAfterSnapshost) {
            queryNotifications = query(
                collection(firestore, "notifications").withConverter(NotificationConverter),
                orderBy("date", "desc"),
                startAfter(startAfterSnapshost),
                limit(rowsPerPage)
            );
        } else if (endBeforeSnapshot) {
            queryNotifications = query(
                collection(firestore, "notifications").withConverter(NotificationConverter),
                orderBy("date", "desc"),
                endBefore(endBeforeSnapshot),
                limitToLast(rowsPerPage)
            );
        } else {
            if (perPage) {
                queryNotifications = query(
                    collection(firestore, "notifications").withConverter(NotificationConverter),
                    orderBy("date", "desc"),
                    limit(perPage)
                );
            } else {
                queryNotifications = query(
                    collection(firestore, "notifications").withConverter(NotificationConverter),
                    orderBy("date", "desc"),
                    limit(rowsPerPage)
                );
            }
        }
        return queryNotifications;
    }

    const memoizedGetNotifications = useMemo(() => {
        return async () => {
            setNotifications([]);
            setPage(0);
            try {
                const queryTotalNotifications = query(
                    collection(firestore, "notifications").withConverter(NotificationConverter)
                );

                const totalNotifications = await getCountFromServer(queryTotalNotifications);
                setCount(totalNotifications.data().count);

                const queryNotifications = query(
                    collection(firestore, "notifications").withConverter(NotificationConverter),
                    orderBy("date", "desc"),
                    limit(rowsPerPage)
                );

                const notificationsSnapshot = await getDocs(queryNotifications);
                const notifications = notificationsSnapshot.docs.map(notification => notification.data()) as Notification[];
                setNotifications(notifications);
                setLastVisibleSnapshot(notificationsSnapshot.docs[notificationsSnapshot.docs.length - 1]);
                setFirstVisibleSnapshot(notificationsSnapshot.docs[0]);
            } catch (error: any) {
                setError(error.message);
            }
        }
    }, [rowsPerPage]);

    useEffect(() => {
        memoizedGetNotifications();
    }, [memoizedGetNotifications])

    return (
        <>
            <Stack direction='column' className={"tools-column"} height={"100%"}>
                {error &&
                    <ErrorResponseDialog errorCode={error} onClose={() => {
                        setError(undefined);
                    }}/>
                }
                <Admin/>
                <Divider/>
                <Stack padding={1}>
                    <Stack gap={1} mt={1}>
                        <Typography variant={"h4"}>
                            {t("notifications_title")}
                        </Typography>
                        <NotificationList notifications={notifications}/>
                        {notifications.length &&
                            <TablePagination
                                sx={{
                                    paddingRight: 0,
                                    '& .MuiTablePagination-selectLabel': {
                                        fontSize: '11px'
                                    },
                                    '& .MuiTablePagination-displayedRows': {
                                        fontSize: '11px'
                                    },
                                    '& .MuiTablePagination-input': {
                                        fontSize: '11px'
                                    }
                                }}
                                labelRowsPerPage={t("notifications_rows_per_page_title")}
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={onPageChanged}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Stack>
                    <Menu/>
                </Stack>
            </Stack>
        </>
    )
}