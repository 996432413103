import {Drawer} from "@mui/material";
import {ReactNode} from "react";

interface Props {
    mobileOpen: boolean;
    handleDrawerToggle: any;
    children: ReactNode;
}

export default function MobileDrawer({mobileOpen, handleDrawerToggle, children}: Props) {
    return (
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true
            }}
            sx={{
                display: {xs: 'block', md: 'none'},
                '& .MuiDrawer-paper': {overflowY: "unset", boxSizing: 'border-box', width: "80%"},
            }}
        >
            {children}
        </Drawer>
    )
}