import {DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue} from "@firebase/firestore";
import {Notification} from "../models/Notification";
import DateConverter from "./DateConverter";

const NotificationConverter = {
    toFirestore(notification: WithFieldValue<Notification>): DocumentData {
        const {id, ...notificationData} = notification;
        return {...notificationData};
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Notification {
        const data: DocumentData = snapshot.data(options)!;
        return {
            ...data,
            id: snapshot.id,
            date: DateConverter.fromData(data.date)
        } as Notification
    }
}

export default NotificationConverter