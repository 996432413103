import {Rectangle, useMap, useMapEvent} from "react-leaflet";
import {useCallback, useMemo, useState} from "react";
import {useEventHandlers, useLeafletContext} from "@react-leaflet/core";
import {Map} from "leaflet";
import {tcsRed} from "../../mui/Theme";

const BOUNDS_STYLE = {
    color: tcsRed
}

interface Props {
    parentMap: Map;
    zoom: number;
}

export default function LMinimapBounds({parentMap, zoom}: Props) {
    const minimap = useMap();
    const context = useLeafletContext();

    const onClick = useCallback(
        (e: any) => {
            parentMap.setView(e.latlng, parentMap.getZoom())
        },
        [parentMap],
    )
    useMapEvent('click', onClick);

    const [bounds, setBounds] = useState(parentMap.getBounds());
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlers = useMemo(() => ({move: onChange, zoom: onChange}), []);
    useEventHandlers({instance: parentMap, context}, handlers);

    return (
        <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} stroke={false}/>
    )
}