'use client'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    errorCode: TemplateStringsArray;
    onClose(...args: any): void;
}

export default function ErrorResponseDialog({errorCode, onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog'>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {errorCode}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t("button_label_close")}</Button>
            </DialogActions>
        </Dialog>
    )
}
