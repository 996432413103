import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import HomeDashboard from "../dashboard/HomeDashboard";
import React from "react";
import NotificationDashboard from "../dashboard/NotificationDashboard";
import UserDashboard from "../dashboard/UserDashboard";
import {UserLocationsProvider} from "../../contexts/UserLocationContext";
import ProtectedRoute from "../ProtectedRoute";
import Main from "../Main";
import i18n from "i18next";

export default function Router() {

    return (
        <Routes>
            <Route path={'/:lang'} element={<ProtectedRoute><Outlet/></ProtectedRoute>}>
                <Route path={''} element={<Main clustering={true}><HomeDashboard/></Main>}/>
                <Route path={'notifications'} element={<Main clustering={true}><NotificationDashboard/></Main>}/>
                <Route path={'user/:userId'} element={<UserLocationsProvider><Main clustering={false}><UserDashboard/></Main></UserLocationsProvider>}/>
            </Route>
            <Route path="*" element={<Navigate to={i18n.language} replace/>}/>
        </Routes>
    )
}