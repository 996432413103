import {Button, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Control from "react-leaflet-custom-control";

interface Props {
    zoomIn(...args: any): void;
    zoomOut(...args: any): void;
}

export default function LMapControl({zoomIn, zoomOut}: Props) {
    return (
        <Control position='bottomright'>
            <Stack className={"map-control"} direction='column' spacing={1} sx={{
                width: "40px",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Button color='inherit' onClick={zoomIn}>
                    <AddIcon fontSize="large"/>
                </Button>
                <Button color='inherit' onClick={zoomOut}>
                    <RemoveIcon fontSize="large"/>
                </Button>
            </Stack>
        </Control>
    )
}