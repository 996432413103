import {MenuItem, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    ageSearch: number;
    handleChangeHours(...args: any): void;
}

export default function AgeFilter({ageSearch, handleChangeHours}: Props) {
    const {t} = useTranslation();

    return (
        <Stack direction={"row"} className={"age-filter"}>
            <Typography variant={"body1"}>
                {t("last_user_locations_age_filter_label")}
            </Typography>
            <TextField
                select
                value={ageSearch}
                onChange={handleChangeHours}
                sx={{
                    '& .MuiSelect-select': {
                        padding: "10px 10px",
                        backgroundColor: "white"
                    }
                }}
            >
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-none"}
                          value={0}>{t("last_user_locations_age_filter_always")}</MenuItem>
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-1"}
                          value={1}>1h</MenuItem>
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-3"}
                          value={3}>3h</MenuItem>
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-12"}
                          value={12}>12h</MenuItem>
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-24"}
                          value={24}>24h</MenuItem>
                <MenuItem className={"age-filter-item"} key={"ageLastUserLocation-72"}
                          value={48}>48h</MenuItem>
            </TextField>
        </Stack>
    )
}