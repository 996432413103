import {LastUserLocation} from "../../utils/models/LastUserLocation";
import AutoSizer from "react-virtualized-auto-sizer";
import LastUserLocationList from "./LastUserLocationList";
import {Stack} from "@mui/material";

interface Props {
    lastUserLocations: LastUserLocation[];
    handleToggle(id: string): any;
    checked: string[];
}

interface Size {
    width: number;
    height: number;
}

export default function LastUserLocationListAdapter({lastUserLocations, handleToggle, checked}: Props) {
    // @ts-ignore
    return (
        <Stack className={"last-user-locations"} mt={2} sx={{height: {md: "35vh", xs: "30vh"}}}>
            <AutoSizer>
                {({ width, height }: Size) => (
                    <LastUserLocationList width={width} height={height} lastUserLocations={lastUserLocations} handleToggle={handleToggle} checked={checked}/>
                )}
            </AutoSizer>
        </Stack>
    )
}