import {Stack} from "@mui/material";
import LMap from "../components/map/LMap";
import {ReactNode, useState} from "react";
import MobileDrawer from "./drawer/MobileDrawer";
import DesktopDrawer from "./drawer/DesktopDrawer";
import CustomBar from "./bar/CustomBar";

interface Props {
    clustering: boolean;
    children: ReactNode
}

export default function Main({clustering, children}: Props) {
    const [mobileOpen, setMobileOpen] = useState(true);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Stack direction='column' sx={{height: "auto", flexShrink: {md: 0}}}>
            <CustomBar handleDrawerToggle={handleDrawerToggle}/>
            <Stack direction='row'>
                <Stack sx={{width: {md: "25%"}, flexShrink: {md: 0}}}>
                    <MobileDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen}>
                        {children}
                    </MobileDrawer>
                    <DesktopDrawer>
                        {children}
                    </DesktopDrawer>
                </Stack>

                <Stack sx={{flexGrow: 1, width: {md: `calc(100% - 25%)`}}}>
                    <LMap clustering={clustering}/>
                </Stack>
            </Stack>
        </Stack>
    )
}