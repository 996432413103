import {Divider, ListItem, Stack, Typography} from "@mui/material";
import {UserLocation} from "../../../utils/models/UserLocation";
import {useUserLocations} from "../../../contexts/UserLocationContext";
import {useLastUserLocations} from "../../../contexts/LastUserLocationContext";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {momentRelativeTimeTranslationsForLocale} from "../../../utils/helpers/datesHelper";

interface Props {
    style: any;
    userLocation: UserLocation;
    index: number;
    length: number;
}

export default function UserLocationItem({style, userLocation, index, length}: Props) {
    const {i18n} = useTranslation();
    const currentLocale = i18n.language;

    moment.locale(currentLocale, momentRelativeTimeTranslationsForLocale(currentLocale));
    const date = moment(userLocation.date);

    const {
        setSelectedUserLocationMarker,
        selectedUserLocationMarker
    } = useUserLocations();

    const {
        zoom,
        setView
    } = useLastUserLocations();

    return (
        <>
            <ListItem
                style={style}
                onClick={() => {
                    setSelectedUserLocationMarker(userLocation);
                    setView({
                        location: {lat: userLocation.location.lat, lng: userLocation.location.lng},
                        zoom: zoom,
                        options: {animate: true}
                    })
                }}
                sx={{
                    backgroundColor: index % 2 ? "#e9f1f5" : "white",
                    "&.Mui-selected": {
                        backgroundColor: "#f294b4"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#f294b4"
                    },
                    ":hover": {
                        backgroundColor: "white"
                    }
                }}
                autoFocus={selectedUserLocationMarker?.id === userLocation.id}
                className={selectedUserLocationMarker?.id === userLocation.id ? "dashboard-list-item-active" : ""}>
                <Stack direction='column' sx={{mt: 1}}>
                    <Typography
                        variant={"body1"}>
                        {userLocation.location.formattedAddress}
                    </Typography>
                    <Typography
                        variant={"body1Bold"}>
                        {date.fromNow() + " (" + userLocation.date.toLocaleString() + ")"}
                    </Typography>
                </Stack>
            </ListItem>
            {index !== length - 1 &&
                <Divider/>
            }
        </>
    )
}