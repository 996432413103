import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {LastUserLocation} from "../utils/models/LastUserLocation";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, orderBy, query} from "@firebase/firestore";
import {firestore} from "../firebase/config/config";
import LastUserLocationConverter from "../utils/converters/LastUserLocationConverter";
import {LatLng, LatLngBounds, LatLngLiteral} from "leaflet";
import {DEFAULT_RADIUS_SLIDER} from "../leaflet/map";
import {newDate_hoursFromNow} from "../utils/helpers/datesHelper";

const DEFAULT_ZOOM = 3;

const europeBounds = new LatLngBounds(
    {lat: 30.524413269923986, lng: -24.521484375000004},
    {lat: 59.24341475839977, lng: 41.08886718750001}
);

const DEFAULT_CENTER: LatLngLiteral = {lat: 46.801111, lng: 8.226667};

interface View {
    location: {lat: number, lng: number},
    zoom: number,
    options: {animate: boolean}
}

interface LastUserLocationsContextInterface {
    isLoading: boolean;
    lastUserLocationsInView: LastUserLocation[];

    circleCenter: LatLng | null;
    setCircleCenter(center: LatLng | null): void;

    circleRadius: number | null;
    setCircleRadius(radius: number | null): void;

    drawCircle: boolean;
    setDrawCircle(drawCircle: boolean): void;

    zoom: number;
    setZoom(zoom: number): void;

    center: LatLngLiteral;
    setCenter(center: LatLngLiteral): void;

    textSearch: string;
    setTextSearch(textSearch: string): void;

    bound: LatLngBounds;
    setBound(bound: LatLngBounds): void;

    selectedLastUserLocationMarker: LastUserLocation | undefined;
    setSelectedLastUserLocationMarker(marker: LastUserLocation | undefined): void;

    view: View | null;
    setView(view: View | null): void;

    ageSearch: number;
    setAgeSearch(hours: number): void;
}

const lastUserLocationsDefaultContext = createContext<LastUserLocationsContextInterface>({
    isLoading: true,
    lastUserLocationsInView: [],
    circleCenter: null,
    setCircleCenter: (center: LatLng) => {
        return;
    },
    circleRadius: DEFAULT_RADIUS_SLIDER,
    setCircleRadius: (radius: number | null) => {
        return;
    },
    drawCircle: false,
    setDrawCircle: (drawCircle: boolean) => {
        return;
    },
    zoom: DEFAULT_ZOOM,
    setZoom: (zoom: number) => {
        return;
    },
    center: DEFAULT_CENTER,
    setCenter: (center: LatLngLiteral) => {
        return;
    },
    textSearch: "",
    setTextSearch(textSearch: string) {
        return;
    },
    bound: europeBounds,
    setBound: (bound: LatLngBounds) => {
        return;
    },
    selectedLastUserLocationMarker: undefined,
    setSelectedLastUserLocationMarker: (marker: LastUserLocation | undefined) => {
        return;
    },
    view: null,
    setView: (view: View | null) => {
        return;
    },
    ageSearch: 0,
    setAgeSearch: (hours: number) => {
        return;
    }
})

export const useLastUserLocations = () => useContext(lastUserLocationsDefaultContext);

export const LastUserLocationsProvider = ({children}: { children: ReactNode }) => {
    const [lastUserLocationsSnapshot, loading] = useCollection(
        query(
            collection(firestore, "lastUserLocations").withConverter(LastUserLocationConverter),
            orderBy("date", "desc")
        ),
        {
            snapshotListenOptions: {includeMetadataChanges: false},
        }
    );
    const [lastUserLocations, setLastUserLocations] = useState<LastUserLocation[]>([]);

    const [bound, setBound] = useState<LatLngBounds>(europeBounds);
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [center, setCenter] = useState<LatLngLiteral>(DEFAULT_CENTER);

    const [lastUserLocationsInView, setLastUserLocationsInView] = useState<LastUserLocation[]>([]);

    const [circleCenter, setCircleCenter] = useState<LatLng | null>(null);
    const [circleRadius, setCircleRadius] = useState<number | null>(DEFAULT_RADIUS_SLIDER);
    const [drawCircle, setDrawCircle] = useState<boolean>(false);

    const [textSearch, setTextSearch] = useState<string>("");
    const [ageSearch, setAgeSearch] = useState<number>(0);

    const [selectedLastUserLocationMarker, setSelectedLastUserLocationMarker] = useState<LastUserLocation | undefined>(undefined);

    const [view, setView] = useState<View | null>(null);

    useEffect(() => {
        if (lastUserLocationsSnapshot) {
            setLastUserLocations(lastUserLocationsSnapshot.docs.map(item => item.data()) as LastUserLocation[]);
        }
    }, [lastUserLocationsSnapshot]);

    useEffect(() => {
        const lastUserLocationsDisplayed = getLastUserLocationsInView(bound);
        setLastUserLocationsInView(lastUserLocationsDisplayed);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUserLocations]);

    useEffect(() => {
        let lastUserLocationsToDisplay: LastUserLocation[] = [];
        const lastUserLocationsInViewport = getLastUserLocationsInView(bound);
        lastUserLocationsToDisplay = [...lastUserLocationsInViewport];
        if (circleRadius && circleCenter) {
            lastUserLocationsToDisplay = getLastUserLocationsInCircle(
                circleCenter,
                circleRadius,
                lastUserLocationsToDisplay
            );
        }
        if (textSearch !== "") {
            lastUserLocationsToDisplay = getLastUserLocationsInViewByTextSearch(textSearch, lastUserLocationsToDisplay);
        }
        if (ageSearch !== 0) {
            lastUserLocationsToDisplay = getLastUserLocationsByAge(ageSearch, lastUserLocationsToDisplay);
        }
        setLastUserLocationsInView(lastUserLocationsToDisplay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bound, textSearch, circleRadius, circleCenter, ageSearch]);

    const getLastUserLocationsInView = (bounds: LatLngBounds) => {
        return lastUserLocations.filter((lastUserLocation: LastUserLocation) => {
            return bounds.contains({lat: lastUserLocation.location.lat, lng: lastUserLocation.location.lng});
        });
    }

    const getLastUserLocationsInViewByTextSearch = (search: string, lastUserLocations: LastUserLocation[]) => {
        return lastUserLocations.filter((lastUserLocation: LastUserLocation) => {
            return (lastUserLocation.user.personalReference.includes(search) ||
                lastUserLocation.user.displayName.toLowerCase().includes(search) ||
                lastUserLocation.location.formattedAddress.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(search))
        });
    }

    const getLastUserLocationsInCircle = (center: LatLng, radius: number, lastUserLocations: LastUserLocation[]) => {
        return lastUserLocations.filter((lastUserLocation: LastUserLocation) => {
            return center.distanceTo({lat: lastUserLocation.location.lat, lng: lastUserLocation.location.lng}) < radius;
        })
    }

    const getLastUserLocationsByAge = (hours: number, lastUserLocations: LastUserLocation[]) => {
        return lastUserLocations.filter((lastUserLocation: LastUserLocation) => {
            return lastUserLocation.date >= newDate_hoursFromNow(-hours);
        })
    }

    const lastUserLocationsProvider: LastUserLocationsContextInterface = {
        isLoading: loading,
        lastUserLocationsInView: lastUserLocationsInView,
        circleCenter: circleCenter,
        setCircleCenter: setCircleCenter,
        circleRadius: circleRadius,
        setCircleRadius: setCircleRadius,
        drawCircle: drawCircle,
        setDrawCircle: setDrawCircle,
        zoom: zoom,
        setZoom: setZoom,
        center: center,
        setCenter: setCenter,
        textSearch: textSearch,
        setTextSearch: setTextSearch,
        bound: bound,
        setBound: setBound,
        selectedLastUserLocationMarker: selectedLastUserLocationMarker,
        setSelectedLastUserLocationMarker: setSelectedLastUserLocationMarker,
        view: view,
        setView: setView,
        ageSearch: ageSearch,
        setAgeSearch: setAgeSearch
    }

    return (
        <lastUserLocationsDefaultContext.Provider value={lastUserLocationsProvider}>
            {children}
        </lastUserLocationsDefaultContext.Provider>
    )
}
