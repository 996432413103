import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    message: string,
    onClose(...args: any): void
}

export default function DialogNotificationResponse({message, onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={true}
            onClose={onClose}
            className='dialog'>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onClose}>
                    {t("button_label_close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}