import {MapContainer, TileLayer, useMap} from "react-leaflet";
import {useMemo, useState} from "react";
import LMinimapBounds from "./LMinimapBounds";

export default function LMapMinimapControl() {
    const parentMap = useMap();
    const mapZoom = 0;
    const [zoomLevel, setZoomLevel] = useState<number>(100);

    const onMouseEnter = () => {
        setZoomLevel(130);
    }

    const onMouseLeave = () => {
        setZoomLevel(100);
    }

    const minimap = useMemo(
        () => (
            <div style={{ height: zoomLevel, width: zoomLevel }}>
                <MapContainer
                    style={{height: "100%", width: "100%"}}
                    center={parentMap.getCenter()}
                    zoom={mapZoom}
                    dragging={false}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    zoomControl={false}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    <LMinimapBounds parentMap={parentMap} zoom={mapZoom}/>
                </MapContainer>
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [zoomLevel],
    )

    return (
        <div className={'leaflet-top leaflet-right'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="leaflet-control leaflet-bar">{minimap}</div>
        </div>
    )
}
