import {IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import {useLastUserLocations} from "../contexts/LastUserLocationContext";
import React from "react";

export default function SearchBar() {
    const {
        textSearch,
        setTextSearch
    } = useLastUserLocations();

    const clearSearch = () => {
        setTextSearch("");
    }

    return (
        <Stack direction='row' className={"search-bar"}>
            <TextField
                sx={{
                    width: "100%",
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: "white"
                    }
                }}
                size="small"
                value={textSearch}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTextSearch(String(event.target.value).toLowerCase());
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <IconButton onClick={clearSearch} edge="end">
                            <ClearIcon/>
                        </IconButton>
                    )
                }}
            />
        </Stack>
    )
}