import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Theme} from "../mui/Theme";
import {i18nConfig} from "../i18nConfig";

export default function LanguageSwitcher() {
    const {i18n} = useTranslation();
    const currentLocale = i18n.language;
    const location = useLocation();
    const navigate = useNavigate();

    const toggleLanguage = async (newLocale: string) => {
        await i18n.changeLanguage(newLocale);
        navigate(location.pathname.replace(`/${currentLocale}`, `/${newLocale}`));
    };

    return (
        <ToggleButtonGroup size="small" value={i18n.language} exclusive>
            {i18nConfig.locales.map((language: string) => {
                return <ToggleButton
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 1,
                        paddingLeft: 1,
                        fontSize: "11px",
                        "&.Mui-selected": {
                            color: "white",
                            backgroundColor: Theme.palette.secondary.main,
                            "&:hover": {
                                color: "white",
                                backgroundColor: Theme.palette.secondary.main,
                            },
                        },
                        "&:hover": {
                            color: "white",
                            backgroundColor: Theme.palette.secondary.main,
                        }
                    }}
                    onClick={() => toggleLanguage(language)}
                    value={language}
                    key={language}>
                    {language}
                </ToggleButton>
            })}
        </ToggleButtonGroup>
    )
}