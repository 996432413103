import {Drawer} from "@mui/material";
import {ReactNode} from "react";

interface Props {
    children: ReactNode;
}

export default function DesktopDrawer({children}: Props) {
    return (
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', md: 'block'},
                '& .MuiDrawer-paper': {overflowY: "unset", boxSizing: 'border-box', width: "25%"},
            }}
            open
        >
            {children}
        </Drawer>
    )
}