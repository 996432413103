import {Checkbox, Divider, ListItem, Stack, Typography} from "@mui/material";
import {LastUserLocation} from "../../../utils/models/LastUserLocation";
import {useLastUserLocations} from "../../../contexts/LastUserLocationContext";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {momentRelativeTimeTranslationsForLocale} from "../../../utils/helpers/datesHelper";
import {useNavigate} from "react-router-dom";

interface Props {
    style: any;
    lastUserLocation: LastUserLocation;
    handleToggle(id: string): any;
    checked: string[];
    index: number;
    length: number;
}

export default function LastUserLocationItem({style, lastUserLocation, handleToggle, checked, index, length}: Props) {
    const labelId = `checkbox-list-secondary-label-${lastUserLocation.id}`;
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const currentLocale = i18n.language;

    moment.locale(currentLocale, momentRelativeTimeTranslationsForLocale(currentLocale));
    const date = moment(lastUserLocation.date);

    const {
        selectedLastUserLocationMarker,
        setSelectedLastUserLocationMarker,
        zoom,
        setView
    } = useLastUserLocations();

    const onLink = (azureId: string) => {
        navigate("/" + i18n.language + "/user/" + azureId);
    }

    return (
        <>
            <ListItem
                style={style}
                onClick={() => {
                    if (selectedLastUserLocationMarker && selectedLastUserLocationMarker.id === lastUserLocation.id) {
                        setSelectedLastUserLocationMarker(undefined);
                    } else {
                        setSelectedLastUserLocationMarker(lastUserLocation);
                        setView({
                            location: {lat: lastUserLocation.location.lat, lng: lastUserLocation.location.lng},
                            zoom: zoom,
                            options: {animate: true}
                        });
                    }
                }}
                sx={{
                    backgroundColor: index % 2 ? "#e9f1f5" : "white",
                    padding: 1,
                    "&.Mui-selected": {
                        backgroundColor: "#f294b4"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#f294b4"
                    },
                    ":hover": {
                        backgroundColor: index % 2 ? "#e9f1f5" : "white"
                    }
                }}
                className={selectedLastUserLocationMarker?.id === lastUserLocation.id ? "dashboard-list-item-active" : ""}>
                <Stack direction={'row'} sx={{justifyContent: "space-between", width: "100%"}}>
                    <Stack direction='column'>
                        <Typography
                            variant={"body1Bold"}>
                            {lastUserLocation.user.displayName}
                        </Typography>
                        <Typography
                            variant={"body2"}>
                            {lastUserLocation.user.personalReference} - {lastUserLocation.user.membership}
                        </Typography>
                        <Stack direction='column' sx={{mt: 1}}>
                            <Typography
                                variant={"body2"}>
                                Last location
                            </Typography>
                            <Typography
                                variant={"body2"}>
                                {lastUserLocation.location.formattedAddress}
                            </Typography>
                            <Typography
                                variant={"body1Bold"}>
                                {date.fromNow()}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={"column"}
                        sx={{justifyContent: "space-between"}}>
                        <Checkbox
                            color="secondary"
                            onClick={(event) => event.stopPropagation()}
                            onChange={handleToggle(lastUserLocation.id)}
                            checked={checked.indexOf(lastUserLocation.id) !== -1}
                            inputProps={{'aria-labelledby': labelId}}
                        />
                        <Typography
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                onLink(lastUserLocation.user.azureId);
                            }}
                            variant={"body2"}>
                            {t("last_user_location_item_see_more") + " >"}
                        </Typography>
                    </Stack>
                </Stack>
            </ListItem>
            {index !== length - 1 &&
                <Divider/>
            }
        </>
    )
}