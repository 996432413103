import {httpsCallable, HttpsCallableResult} from "@firebase/functions";
import {NotificationDTO} from "./models/NotificationDTO";
import {functions} from "../firebase/config/config";

const httpCreateNotification = httpsCallable(functions, 'httpCreateNotification');
export const createNotification = async (notificationDTO: NotificationDTO) => {
    try {
        const result = await httpCreateNotification(notificationDTO);
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}
