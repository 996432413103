import {LastUserLocation} from "../models/LastUserLocation";
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue} from "@firebase/firestore";
import DateConverter from "./DateConverter";

const LastUserLocationConverter = {
    toFirestore(LastUserLocation: WithFieldValue<LastUserLocation>): DocumentData {
        return {...LastUserLocation};
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): LastUserLocation {
        const data: DocumentData = snapshot.data(options)!;
        return {
            ...data,
            id: snapshot.id,
            date: DateConverter.fromData(data.date)
        } as LastUserLocation
    }
}

export default LastUserLocationConverter