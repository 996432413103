import {AppBar, IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

interface Props {
    handleDrawerToggle: any;
}

export default function CustomBar({handleDrawerToggle}: Props) {
    return (
        <AppBar
            className={"app-bar-mobile"}
            position="fixed"
        >
            <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {md: 'none'}}}
            >
                <MenuIcon/>
            </IconButton>
        </AppBar>
    )
}