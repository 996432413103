import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {useState} from "react";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Theme} from "../../mui/Theme";
import {DocumentData} from "firebase/firestore";
import SendIcon from "@mui/icons-material/Send";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import DialogLoader from "./DialogLoader";
import ErrorResponseDialog from "./ErrorResponseDialog";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {LastUserLocation} from "../../utils/models/LastUserLocation";

interface Props {
    open: boolean;
    handleClose(): void;
    lastUserLocationsSelected: LastUserLocation[];
    onCreate(
        notification: DocumentData,
    ): void
}

const DEFAULT_MIN_SIZE_TITLE = 5;
const DEFAULT_MIN_SIZE_CONTENT = 50;

const DEFAULT_MAX_SIZE_TITLE = 50;
const DEFAULT_MAX_SIZE_CONTENT = 150;

const apiUrl = "https://translation.googleapis.com/language/translate/v2";
const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATION_API_KEY!;

const supportedLanguages = ['fr', 'de', 'it'];

export default function DialogNewNotification({open, handleClose, lastUserLocationsSelected, onCreate}: Props) {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;

    const [notification, setNotification] = useState<Record<string, Record<string, string>>>({
        fr: { title: "", content: "" },
        de: { title: "", content: "" },
        it: { title: "", content: "" }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getTranslationTitle = async (text: string, target: string, source: string) => {
        const translations = await axios.post(
            apiUrl + "?key=" + apiKey,
            {
                q: text,
                source: source,
                target: target,
                format: "text"
            }
        );
        setNotification((prevNotification) => ({
            ...prevNotification,
            [target]: { ...prevNotification[target], title: translations.data.data.translations[0].translatedText ?? "error" }
        }));
    }

    const getTranslationContent = async (text: string, target: string, source: string) => {
        const translations = await axios.post(
            apiUrl + "?key=" + apiKey,
            {
                q: text,
                source: source,
                target: target,
                format: "text"
            }
        );
        setNotification((prevNotification) => ({
            ...prevNotification,
            [target]: { ...prevNotification[target], content: translations.data.data.translations[0].translatedText ?? "error" }
        }));
    }

    const getDefaultTitle = (language: string) => {
        setNotification((prevNotification) => ({
            ...prevNotification,
            [language]: { ...prevNotification[language], title: t("dialog_create_notification_default_title") }
        }));
    };

    const getDefaultContent = (language: string) => {
        setNotification((prevNotification) => ({
            ...prevNotification,
            [language]: { ...prevNotification[language], content: t("dialog_create_notification_default_content") }
        }));
    };

    const onCreateNotification = async () => {
        if (!isValidNotification()) return;
        const notificationDocument = {
            date: new Date(),
            title_FR: notification.fr.title,
            title_DE: notification.de.title,
            title_IT: notification.it.title,
            content_FR: notification.fr.content,
            content_DE: notification.de.content,
            content_IT: notification.it.content,
            users: lastUserLocationsSelected
        } as DocumentData;
        await onCreate(notificationDocument);
    };

    const isValidTitle = (title: string) => {
        return title.length >= DEFAULT_MIN_SIZE_TITLE && title.length <= DEFAULT_MIN_SIZE_CONTENT;
    }

    const isValidContent = (content: string) => {
        return content.length >= DEFAULT_MIN_SIZE_CONTENT && content.length <= DEFAULT_MAX_SIZE_CONTENT;
    }

    const isValidNotification = () => {
        return (
            isValidTitle(notification.fr.title) &&
            isValidTitle(notification.de.title) &&
            isValidTitle(notification.it.title) &&
            isValidContent(notification.fr.content) &&
            isValidContent(notification.de.content) &&
            isValidContent(notification.it.content)
        );
    }

    const getHelperTextElement = (text: string, minSize: number, maxSize: number) => {
        const error = text.length < minSize || text.length > maxSize;
        return (
            <span style={{color: error ? Theme.palette.error.main : Theme.palette.success.main}}
                  className={"custom-helper"}>
                {error ?
                    <ErrorIcon fontSize={"small"}/> :
                    <CheckCircleIcon fontSize={"small"}/>
                }
                <Typography
                    variant={"body1"}
                    fontSize={11}
                    component="span">
                    {
                        t("dialog_create_notification_helper_title", {
                            min: minSize ?? '{{min}}',
                            max: maxSize ?? '{{max}}',
                        }) +
                        " - " +
                        text.length
                    }
                </Typography>
            </span>
        );
    };

    const getColor = (text: string, minSize: number, maxSize: number) => {
        const error = text.length < minSize || text.length > maxSize;
        return error ? Theme.palette.error.main : Theme.palette.success.main;
    }

    return (
        <>
            {isLoading &&
                <DialogLoader loading={isLoading} onClose={() => setIsLoading(!isLoading)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            <Dialog
                maxWidth={false}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                className={"notification-dialog"}
            >
                <Typography variant={"h4"} sx={{mt: 2, ml: 2}}>
                    {t("dialog_create_notification_title")}
                </Typography>
                <DialogContent sx={{mt: 2, ml:2, mr: 2, p: 0}}>
                    <Typography variant={"body1"}>
                        {t("dialog_create_notification_subtitle", {
                            number_users: lastUserLocationsSelected.length ?? '{{number_users}}'
                        })}
                    </Typography>
                    <Stack sx={{flexDirection: { xs: "column", md: "row"}, justifyContent: "center", mt: 2}} gap={3}>

                        <Stack key={currentLocale} direction="column" gap={2}>
                            <Typography variant={"body1Bold"}>{currentLocale.toUpperCase()}</Typography>
                            <Typography variant={"body1"}>{t("dialog_create_notification_title_title")}</Typography>
                            <Button
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => getDefaultTitle(currentLocale)}
                            >
                                <FormatColorFillIcon /> {t("dialog_create_notification_button_prefill")}
                            </Button>
                            <TextField
                                value={notification[currentLocale].title}
                                error={!isValidTitle(notification[currentLocale].title)}
                                variant="outlined"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setNotification((prevNotification) => ({
                                        ...prevNotification,
                                        [currentLocale]: { ...prevNotification[currentLocale], title: event.target.value }
                                    }));
                                }}
                                helperText={getHelperTextElement(
                                    notification[currentLocale].title,
                                    DEFAULT_MIN_SIZE_TITLE,
                                    DEFAULT_MAX_SIZE_TITLE
                                )}
                                sx={{
                                    "& label.Mui-focused": {
                                        color: getColor(notification[currentLocale].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: getColor(notification[currentLocale].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                    },
                                    "& .MuiFilledInput-underline:after": {
                                        borderBottomColor: getColor(notification[currentLocale].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: getColor(notification[currentLocale].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                        }
                                    }
                                }}
                            />
                            <Typography variant={"body1"}>{t("dialog_create_notification_content_title")}</Typography>
                            <Button
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => getDefaultContent(currentLocale)}
                            >
                                <FormatColorFillIcon /> {t("dialog_create_notification_button_prefill")}
                            </Button>
                            <TextField
                                value={notification[currentLocale].content}
                                error={!isValidContent(notification[currentLocale].content)}
                                variant="outlined"
                                multiline
                                rows={4}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setNotification((prevNotification) => ({
                                        ...prevNotification,
                                        [currentLocale]: { ...prevNotification[currentLocale], content: event.target.value }
                                    }));
                                }}
                                helperText={getHelperTextElement(
                                    notification[currentLocale].content,
                                    DEFAULT_MIN_SIZE_CONTENT,
                                    DEFAULT_MAX_SIZE_CONTENT
                                )}
                                sx={{
                                    "& label.Mui-focused": {
                                        color: getColor(notification[currentLocale].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: getColor(notification[currentLocale].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                    },
                                    "& .MuiFilledInput-underline:after": {
                                        borderBottomColor: getColor(notification[currentLocale].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: getColor(notification[currentLocale].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                        }
                                    }
                                }}
                            />
                        </Stack>

                        {supportedLanguages.filter((language: string) => language !== currentLocale).map((language: string) => (
                            <Stack key={language} direction="column" gap={2}>
                                <Typography variant={"body1Bold"}>{language.toUpperCase()}</Typography>
                                <Typography variant={"body1"}>{t("dialog_create_notification_title_title")}</Typography>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => getTranslationTitle(notification[currentLocale].title, language, currentLocale)}>
                                    <GTranslateIcon/> {t("dialog_create_notification_button_translate")}
                                </Button>
                                <TextField
                                    value={notification[language].title}
                                    error={!isValidTitle(notification[language].title)}
                                    variant="outlined"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setNotification((prevNotification) => ({
                                            ...prevNotification,
                                            [language]: { ...prevNotification[language], title: event.target.value }
                                        }));
                                    }}
                                    helperText={getHelperTextElement(
                                        notification[language].title,
                                        DEFAULT_MIN_SIZE_TITLE,
                                        DEFAULT_MAX_SIZE_TITLE
                                    )}
                                    sx={{
                                        "& label.Mui-focused": {
                                            color: getColor(notification[language].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: getColor(notification[language].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                        },
                                        "& .MuiFilledInput-underline:after": {
                                            borderBottomColor: getColor(notification[language].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: getColor(notification[language].title, DEFAULT_MIN_SIZE_TITLE, DEFAULT_MAX_SIZE_TITLE)
                                            }
                                        }
                                    }}
                                />
                                <Typography variant={"body1"}>{t("dialog_create_notification_content_title")}</Typography>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => getTranslationContent(notification[currentLocale].content, language, currentLocale)}>
                                    <GTranslateIcon/> {t("dialog_create_notification_button_translate")}
                                </Button>
                                <TextField
                                    value={notification[language].content}
                                    error={!isValidContent(notification[language].content)}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setNotification((prevNotification) => ({
                                            ...prevNotification,
                                            [language]: { ...prevNotification[language], content: event.target.value }
                                        }));
                                    }}
                                    helperText={getHelperTextElement(
                                        notification[language].content,
                                        DEFAULT_MIN_SIZE_CONTENT,
                                        DEFAULT_MAX_SIZE_CONTENT
                                    )}
                                    sx={{
                                        "& label.Mui-focused": {
                                            color: getColor(notification[language].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: getColor(notification[language].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                        },
                                        "& .MuiFilledInput-underline:after": {
                                            borderBottomColor: getColor(notification[language].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: getColor(notification[language].content, DEFAULT_MIN_SIZE_CONTENT, DEFAULT_MAX_SIZE_CONTENT)
                                            }
                                        }
                                    }}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="warning" onClick={handleClose}>{t("button_label_cancel")}</Button>
                    <Button
                        disabled={!isValidNotification()}
                        color="secondary"
                        variant="contained"
                        onClick={onCreateNotification}>
                        <SendIcon/>
                        {t("button_label_send")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}