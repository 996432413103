import Admin from "../Admin";
import {Button, Divider, Stack, Typography} from "@mui/material";
import Menu from "../Menu";
import {ChangeEvent, useEffect, useState} from "react";
import {useUserLocations} from "../../contexts/UserLocationContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";
import AgeFilter from "../AgeFilter";
import {useNavigate} from "react-router-dom";
import UserLocationListAdapter from "../list/UserLocationListAdapter";

export default function UserDashboard() {
    const [name, setName] = useState<string>("");
    const [membership, setMembership] = useState<string>("");
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {
        userLocationsInView,
        ageSearch,
        setAgeSearch
    } = useUserLocations();

    const handleChangeAgeUserLocation = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const hours = parseInt(event.target.value);
        setAgeSearch(hours);
    };

    useEffect(() => {
        if (userLocationsInView.length > 0) {
            setName(userLocationsInView[0].user.displayName);
            setMembership(userLocationsInView[0].user.membership);
        }
    }, [userLocationsInView])

    return (
        <>
            <Stack direction='column' className={"tools-column"} height={"100%"}>
                <Admin/>
                <Divider/>
                <Stack padding={1}>
                    <Stack gap={1} mt={1}>
                        <Stack direction='row' sx={{alignItems: "center"}}>
                            <Button
                                sx={{
                                    minWidth: "auto",
                                    color: "black"
                                }}
                                onClick={() => navigate("/" + i18n.language)}>
                                <ArrowBackIcon/>
                            </Button>
                            <Typography variant={"h5"}>
                                {t("user_details_title")}
                            </Typography>
                        </Stack>
                        <Divider/>
                        <Stack paddingTop={1} paddingBottom={1}>
                            <Typography variant={"body1"}>
                                {t("user_details_know_name_title")}
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"body1"}>
                                {name}
                            </Typography>
                        </Stack>
                        <Divider/>
                        <Stack paddingTop={1} paddingBottom={1}>
                            <Typography variant={"body1"}>
                                {t("user_details_membership_title")}
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"body1"}>
                                {membership}
                            </Typography>
                        </Stack>
                        <Divider/>
                        <AgeFilter ageSearch={ageSearch} handleChangeHours={handleChangeAgeUserLocation}/>
                    </Stack>
                    <UserLocationListAdapter userLocations={userLocationsInView}/>
                    <Menu/>
                </Stack>
            </Stack>
        </>
    )
}