import {Avatar, Button, Stack, Typography} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import LogoutIcon from '@mui/icons-material/Logout';
import {Theme} from "../mui/Theme";
import LanguageSwitcher from "./LanguageSwitcher";

export default function Admin() {
    const {user, logout} = useAuth();

    return (
        <Stack className={"dashboard-user"}  direction='column' padding={1} gap={1}>
            <Stack direction='row' sx={{width: "100%", justifyContent: "space-between"}}>
                <Stack direction='row' gap={1} sx={{alignItems: "center"}}>
                    {user?.displayName &&
                        <>
                            <Avatar sizes="small" alt={user.displayName} />
                            <Typography variant={"body1"} fontSize={15}>
                                {user.displayName}
                            </Typography>
                        </>
                    }
                </Stack>
                <Button
                    sx={{
                        minWidth: "auto",
                        color: "black",
                        "&:hover": {
                            color: Theme.palette.secondary.main
                        }
                    }}
                    onClick={logout}>
                    <LogoutIcon/>
                </Button>
            </Stack>
            <LanguageSwitcher/>
        </Stack>

    )
}