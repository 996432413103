import {LastUserLocation} from "../../utils/models/LastUserLocation";
import LastUserLocationItem from "./item/LastUserLocationItem";
import {useEffect, useRef} from "react";
import {useLastUserLocations} from "../../contexts/LastUserLocationContext";
import {FixedSizeList, ListChildComponentProps} from "react-window";

interface Props {
    lastUserLocations: LastUserLocation[];
    handleToggle(id: string): any;
    checked: string[];
    width: number;
    height: number;
}

export default function LastUserLocationList({width, height, lastUserLocations, handleToggle, checked}: Props) {
    const listRef = useRef<any>();

    const {
        selectedLastUserLocationMarker
    } = useLastUserLocations();

    const positionSelectedItem = lastUserLocations.findIndex((lastUserLocation: LastUserLocation) => lastUserLocation.id === selectedLastUserLocationMarker?.id);

    const Row = ({index, style, data}: ListChildComponentProps) => {
        const {handleToggle, checked} = data as Props;
        return (
            <LastUserLocationItem
                key={index}
                lastUserLocation={lastUserLocations[index]}
                handleToggle={handleToggle}
                checked={checked}
                index={index}
                length={lastUserLocations.length}
                style={style}
            />
        );
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollToItem(positionSelectedItem, "center");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUserLocations]);

    return (
        <FixedSizeList
            className="List"
            itemData={{handleToggle, checked}}
            height={height}
            itemCount={lastUserLocations.length}
            itemSize={120}
            width={width}
            ref={listRef}
        >
            {Row}
        </FixedSizeList>
    )
}