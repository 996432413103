import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'fr',
        debug: false,
        supportedLngs: ['fr', 'de', 'it'],
        detection: {
            order: ["path", "navigator"]
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false
        },
        initImmediate: false
    });

export default i18n;

