export function newDate_hoursFromDate(hours:number, date:Date): Date {
    return newDate_minutesFromDate(hours * 60, date);
}

export function newDate_minutesFromDate(minutes:number, date:Date): Date {
    return newDate_secondsFromDate(minutes * 60, date);
}

export function newDate_secondsFromDate(seconds:number, date:Date): Date {
    date.setTime(date.getTime() + seconds * 1000);
    return date;
}

export function newDate_hoursFromNow(hours:number): Date {
    return newDate_hoursFromDate(hours, new Date());
}

export function momentRelativeTimeTranslationsForLocale(locale: string) {
    switch (locale.toLowerCase()) {
        case "fr":
            return {
                relativeTime: {
                    future: 'dans %s',
                    past: 'il y a %s',
                    s: '< 1 min',
                    ss: '< 1 min',
                    m: '1 min',
                    mm: '%d min',
                    h: '1 heure',
                    hh: '%d heures',
                    d: '1 jour',
                    dd: '%d jours',
                    M: 'un mois',
                    MM: '%dM',
                    y: 'un an',
                    yy: '%dY'
                }
            }
        case "de":
            return {}
        case "it":
            return {}
    }

    return undefined;
}