import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import React from "react";
import {Theme} from "../../mui/Theme";

interface Props {
    loading: boolean,
    onClose(...args: any): void
}

export default function DialogLoader({loading, onClose}: Props) {
    return (
        <Dialog
            open={loading}
            onClose={onClose}>
            <DialogContent>
                <CircularProgress
                    style={{display: "block", margin: "auto", color: Theme.palette.primary.main}}
                    thickness={5}/>
            </DialogContent>
        </Dialog>
    )
}