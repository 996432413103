import {Divider, ListItemButton, Stack, Typography} from "@mui/material";
import {Notification} from "../../../utils/models/Notification";
import {useState} from "react";
import DialogOpenNotification from "../../dialog/DialogOpenNotification";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {momentRelativeTimeTranslationsForLocale} from "../../../utils/helpers/datesHelper";
import {langFromi18nLang} from "../../../utils/helpers/languagesHelper";
import "moment/locale/de";
import "moment/locale/it";

interface Props {
    notification: Notification;
    index: number;
    length: number;
}

export default function NotificationItem({notification, index, length}: Props) {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    moment.locale(currentLocale, momentRelativeTimeTranslationsForLocale(currentLocale));
    const date = moment(notification.date).fromNow();

    const lang = langFromi18nLang(i18n.language);

    return (
        <>
            {openDialog &&
                <DialogOpenNotification notification={notification} open={openDialog} handleClose={() => setOpenDialog(false)} />
            }
            <ListItemButton
                sx={{padding: 1}}
                onClick={() => {
                    setOpenDialog(true);
                }}
            >
                <Stack direction='column' sx={{mt: 1}}>
                    <Typography
                        variant={"body1"}>
                        {notification[`title_${lang}`]}
                    </Typography>
                    <Typography
                        variant={"body1Bold"}>
                        {date}
                    </Typography>
                    {notification.users &&
                        <Typography
                            variant={"body1"}>
                            {
                                t("notification_item_users", {
                                    users: notification.users.length ?? '{{users}}'
                                })
                            }
                        </Typography>
                    }
                </Stack>
            </ListItemButton>
            {index !== length - 1 &&
                <Divider/>
            }
        </>
    )
}