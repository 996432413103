import {UserLocation} from "../models/UserLocation";
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue} from "@firebase/firestore";
import DateConverter from "./DateConverter";

const UserLocationConverter = {
    toFirestore(LastUserLocation: WithFieldValue<UserLocation>): DocumentData {
        return {...LastUserLocation};
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): UserLocation {
        const data: DocumentData = snapshot.data(options)!;
        return {
            ...data,
            id: snapshot.id,
            date: DateConverter.fromData(data.date)
        } as UserLocation
    }
}

export default UserLocationConverter