import React from 'react';
import '../src/scss/globals.scss';
import "leaflet/dist/leaflet.css";
import Router from "./components/router/Router";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body1Bold: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        body1Bold?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body1Bold: true;
    }
}

function App() {
    return (
        <Router/>
    );
}

export default App;
