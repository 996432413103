import {Box, Button, Card, CardMedia, Stack, Typography} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import {Theme} from "../mui/Theme";

export default function Login() {
    const {signin} = useAuth();

    const login = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        try {
            await signin();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Stack direction='column'
               sx={{backgroundColor: "#e8e2d5" ,height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Card variant="outlined" sx={{
                padding: 0,
                flexDirection: {xs: "column", md: "row"},
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: "black",
                backgroundColor: Theme.palette.primary.main
            }}>
                <CardMedia
                    component="img"
                    sx={{width: 160}}
                    image="/tcs_logo.webp"
                    alt="TCS Logo"
                />
                <Box onClick={login} sx={{
                    cursor: "pointer",
                    display: 'flex',
                    backgroundColor: Theme.palette.primary.main,
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    padding: {md: 5},
                    paddingTop: {xs: 5},
                    paddingBottom: {xs: 5},
                    borderLeft: {md: "1px solid black"},
                    borderTop: {md: 0, xs: "1px solid black"},
                }}>
                    <Typography variant={"h3"}>
                        Travel Safety
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary">
                        LOGIN
                    </Button>
                </Box>
            </Card>
        </Stack>
    )
}