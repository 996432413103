import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {LastUserLocationsProvider} from "./contexts/LastUserLocationContext";
import {MenuProvider} from "./contexts/MenuContext";
import {AuthContextProvider} from "./contexts/AuthContext";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from '@mui/material';
import './i18n';
import {Theme} from "./mui/Theme";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <CssBaseline/>
        <ThemeProvider theme={Theme}>
            <AuthContextProvider>
                <MenuProvider>
                    <LastUserLocationsProvider>
                        <App/>
                    </LastUserLocationsProvider>
                </MenuProvider>
            </AuthContextProvider>
        </ThemeProvider>
    </BrowserRouter>
);
