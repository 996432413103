import {UserLocation} from "../../utils/models/UserLocation";
import {Stack} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import UserLocationList from "./UserLocationList";

interface Props {
    userLocations: UserLocation[];
}

interface Size {
    width: number;
    height: number;
}

export default function UserLocationListAdapter({userLocations}: Props) {
    // @ts-ignore
    return (
        <Stack className={"user-locations"} mt={2} sx={{height: {md: "35vh", xs: "30vh"}}}>
            <AutoSizer>
                {({ width, height }: Size) => (
                    <UserLocationList width={width} height={height} userLocations={userLocations}/>
                )}
            </AutoSizer>
        </Stack>
    )
}