import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import HistoryIcon from '@mui/icons-material/History';
import {useMenu} from "../contexts/MenuContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function Menu() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {
        currentMenuItem,
        setCurrentMenuItem
    } = useMenu();

    const onLink = (href: string) => {
        navigate("/" + i18n.language + href);
    }

    return (
        <BottomNavigation
            sx={{
                width: {md: "25%", xs:"80%"},
                justifyContent: "center",
                color: "black",
                backgroundColor: "#ffeb00",
                '& .MuiSvgIcon-root': {
                    color: "black"
                },
                '& .Mui-selected': {
                    color: "black",
                    backgroundColor: "#e8e2d5"
                }
            }}
            className={"dashboard-menu"}
            showLabels
            value={currentMenuItem}
            onChange={(event, newValue) => {
                setCurrentMenuItem(newValue);
            }}>
            <BottomNavigationAction
                sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    maxWidth: "50%",
                    padding: 0
                }}
                label={t("menu_map_label")}
                icon={<MapIcon/>}
                onClick={() => onLink("/")}
            />
            <BottomNavigationAction
                sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    maxWidth: "50%",
                    padding: 0
                }}
                label={t("menu_notifications_label")}
                icon={<HistoryIcon/>}
                onClick={() => onLink("/notifications")}
            />
        </BottomNavigation>
    )
}