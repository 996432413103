import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {firestore} from "../firebase/config/config";
import {collection, orderBy, query, where} from "@firebase/firestore";
import UserLocationConverter from "../utils/converters/UserLocationConverter";
import {UserLocation} from "../utils/models/UserLocation";
import {useCollection} from "react-firebase-hooks/firestore";
import {newDate_hoursFromNow} from "../utils/helpers/datesHelper";
import {useParams} from "react-router-dom";

interface UserLocationsContextInterface {
    isLoading: boolean;
    userLocationsInView: UserLocation[];

    ageSearch: number;
    setAgeSearch(hours: number): void;

    selectedUserLocationMarker: UserLocation | undefined;
    setSelectedUserLocationMarker(marker: UserLocation | undefined): void;
}

const userLocationsDefaultContext = createContext<UserLocationsContextInterface>({
    isLoading: true,
    userLocationsInView: [],
    ageSearch: 0,
    setAgeSearch: (hours: number) => {
        return;
    },
    selectedUserLocationMarker: undefined,
    setSelectedUserLocationMarker: (marker: UserLocation | undefined) => {
        return;
    },
});

export const useUserLocations = () => useContext(userLocationsDefaultContext);

export const UserLocationsProvider = ({children}: {children: ReactNode}) => {
    const {userId} = useParams();
    const [userLocationsSnapshot, loading] = useCollection(
        query(
            collection(firestore, 'userLocations').withConverter(UserLocationConverter),
            where('user.azureId', '==', userId),
            orderBy("date", "desc")
        ),
        {
            snapshotListenOptions: {includeMetadataChanges: false},
        }
    );
    const [userLocations, setUserLocations] = useState<UserLocation[]>([]);
    const [userLocationsInView, setUserLocationsInView] = useState<UserLocation[]>([]);

    const [ageSearch, setAgeSearch] = useState<number>(0);

    const [selectedUserLocationMarker, setSelectedUserLocationMarker] = useState<UserLocation | undefined>(undefined);

    useEffect(() => {
        if (userLocationsSnapshot) {
            setUserLocations(userLocationsSnapshot.docs.map(item => item.data()) as UserLocation[]);
        }
    }, [userLocationsSnapshot]);

    useEffect(() => {
        setUserLocationsInView(userLocations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocations]);

    useEffect(() => {
        if (ageSearch !== 0) {
            const userLocationsToDisplay = getUserLocationsByAge(ageSearch, userLocationsInView);
            setUserLocationsInView(userLocationsToDisplay);
        } else {
            setUserLocationsInView(userLocations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ageSearch]);

    const getUserLocationsByAge = (hours: number, userLocations: UserLocation[]) => {
        return userLocations.filter((userLocation: UserLocation) => {
            return userLocation.date >= newDate_hoursFromNow(-hours);
        })
    }

    const userLocationsProvider: UserLocationsContextInterface = {
        isLoading: loading,
        userLocationsInView: userLocationsInView,
        ageSearch: ageSearch,
        setAgeSearch: setAgeSearch,
        selectedUserLocationMarker: selectedUserLocationMarker,
        setSelectedUserLocationMarker: setSelectedUserLocationMarker
    }

    return (
        <userLocationsDefaultContext.Provider value={userLocationsProvider}>
            {children}
        </userLocationsDefaultContext.Provider>
    )
}
