import {createContext, ReactNode, useContext, useState} from "react";

interface MenuContextInterface {
    currentMenuItem: number;
    setCurrentMenuItem(item: number): void;
}

const menuDefaultContext = createContext<MenuContextInterface>({
    currentMenuItem: 0,
    setCurrentMenuItem: (item: number) => {
        return;
    }
})

export const useMenu = () => useContext(menuDefaultContext);

export const MenuProvider = ({children}: { children: ReactNode }) => {
    const [currentMenuItem, setCurrentMenuItem] = useState<number>(0);

    const menuProvider: MenuContextInterface = {
        currentMenuItem: currentMenuItem,
        setCurrentMenuItem: setCurrentMenuItem
    }

    return (
        <menuDefaultContext.Provider value={menuProvider}>
            {children}
        </menuDefaultContext.Provider>
    )
}