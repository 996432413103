import {UserLocation} from "../../utils/models/UserLocation";
import UserLocationItem from "./item/UserLocationItem";
import {useLastUserLocations} from "../../contexts/LastUserLocationContext";
import {useEffect, useRef} from "react";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import {useUserLocations} from "../../contexts/UserLocationContext";

interface Props {
    userLocations: UserLocation[];
    width: number;
    height: number;
}

export default function UserLocationList({width, height, userLocations}: Props) {
    const listRef = useRef<any>();

    const {
        zoom,
        setView
    } = useLastUserLocations();

    const {
        setSelectedUserLocationMarker,
        selectedUserLocationMarker
    } = useUserLocations();

    const userLocationSelectedItem = userLocations.findIndex((userLocation: UserLocation) => userLocation.id === selectedUserLocationMarker?.id);

    const Row = ({index, style}: ListChildComponentProps) => {
        return (
            <UserLocationItem
                key={index}
                userLocation={userLocations[index]}
                index={index}
                length={userLocations.length}
                style={style}
            />
        );
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollToItem(userLocationSelectedItem, "center");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocationSelectedItem]);

    useEffect(() => {
        if (userLocations.length > 0) {
            setSelectedUserLocationMarker(userLocations[0]);
            setView({
                location: {lat: userLocations[0].location.lat, lng: userLocations[0].location.lng},
                zoom: zoom,
                options: {animate: true}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocations])

    return (
        <FixedSizeList
            className="List"
            height={height}
            itemCount={userLocations.length}
            itemSize={80}
            width={width}
            ref={listRef}
        >
            {Row}
        </FixedSizeList>
    )
}