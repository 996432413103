import {Button, Dialog, DialogActions, DialogContent, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Notification} from "../../utils/models/Notification";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {momentRelativeTimeTranslationsForLocale} from "../../utils/helpers/datesHelper";
import {langFromi18nLang} from "../../utils/helpers/languagesHelper";

interface Props {
    notification: Notification;
    open: boolean;
    handleClose(): void;
}

export default function DialogOpenNotification({notification, open, handleClose}: Props) {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const lang = langFromi18nLang(i18n.language);

    moment.locale(currentLocale, momentRelativeTimeTranslationsForLocale(currentLocale));
    const date = moment(notification.date);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            maxWidth={false}
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            className={"notification-dialog"}
        >
            <Typography variant={"h4"} sx={{mt: 2, ml: 2}}>
                {t("dialog_open_notification_title")}
            </Typography>
            <DialogContent sx={{mt: 2, ml:2, mr: 2, p: 0}}>
                <Typography variant={"h5"}>
                    {notification[`title_${lang}`]}
                </Typography>
                <Typography sx={{mb: 1}} variant={"body1"}>
                    {notification[`content_${lang}`]}
                </Typography>
                <Typography
                    variant={"body1Bold"}>
                    {date.fromNow() + " (" + notification.date.toLocaleString() + ")"}
                </Typography>
                <Typography sx={{mt: 1, mb: 1}} variant={"body1"}>
                    {
                        t("dialog_open_notification_number_users", {
                            number_users: notification.users.length ?? '{{number_users}}'
                        })
                    }
                </Typography>
                <Typography
                    variant={"body1Bold"}>
                    {t("dialog_open_notification_users_list")}
                </Typography>
                {notification.users.length > 0 &&
                    <Stack sx={{overflowY: "scroll", height: "50vh", border: "1px solid grey"}}>
                        <pre>
                            {JSON.stringify(notification.users, null, 2) }
                        </pre>
                    </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}>
                    {t("button_label_close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}