import {
    Divider,
    Typography,
    Stack,
    Button,
    Slider
} from "@mui/material";
import Admin from "../Admin";
import {LastUserLocation} from "../../utils/models/LastUserLocation";
import {useLastUserLocations} from "../../contexts/LastUserLocationContext";
import {ChangeEvent, useState} from "react";
import ClearIcon from '@mui/icons-material/Clear';
import Menu from "../Menu";
import SendIcon from '@mui/icons-material/Send';
import DialogNewNotification from "../dialog/DialogNewNotification";
import {DEFAULT_RADIUS_SLIDER, MAX_RADIUS_SLIDER, MIN_RADIUS_SLIDER} from "../../leaflet/map";
import DialogNotificationResponse from "../dialog/DialogNotificationResponse";
import {DocumentData} from "firebase/firestore";
import DialogLoader from "../dialog/DialogLoader";
import ErrorResponseDialog from "../dialog/ErrorResponseDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AgeFilter from "../AgeFilter";
import SearchBar from "../SearchBar";
import ModeIcon from '@mui/icons-material/Mode';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DoneIcon from '@mui/icons-material/Done';
import LastUserLocationListAdapter from "../list/LastUserLocationListAdapter";
import {NotificationDTO} from "../../utils/models/NotificationDTO";
import {createNotification} from "../../utils/Api";
import {useTranslation} from "react-i18next";

export default function HomeDashboard() {
    const {t} = useTranslation();
    const {
        lastUserLocationsInView,
        drawCircle,
        setDrawCircle,
        setCircleRadius,
        setCircleCenter,
        circleRadius,
        circleCenter,
        ageSearch,
        setAgeSearch
    } = useLastUserLocations();

    const [checked, setChecked] = useState<string[]>([]);
    const [lastUserLocationsSelected, setLastUserLocationsSelected] = useState<LastUserLocation[]>([]);

    const [openCreateNotification, setOpenCreateNotification] = useState<boolean>(false);

    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        let newLastUserLocationsSelected = [...lastUserLocationsSelected];
        if (currentIndex === -1) {
            newChecked.push(value);
            const selectedLastUserLocation = lastUserLocationsInView.find((item: LastUserLocation) => item.id === value);
            if (selectedLastUserLocation !== undefined) {
                newLastUserLocationsSelected.push(selectedLastUserLocation);
            }
        } else {
            newChecked.splice(currentIndex, 1);
            newLastUserLocationsSelected = newLastUserLocationsSelected.filter((item: LastUserLocation) => item.id !== value);
        }
        setLastUserLocationsSelected(newLastUserLocationsSelected);
        setChecked(newChecked);
    }

    const onDrawCircle = () => {
        setDrawCircle(true);
        setChecked([]);
    }

    const changeCircleRadiusValue = (event: any, value: number | number[]) => {
        setCircleRadius(value as number);
        setChecked([]);
    }

    const onDeleteCircle = () => {
        setCircleRadius(null);
        setCircleCenter(null);
    }

    const onSelectOrDeselectAll = () => {
        if (checked.length > 0) {
            setLastUserLocationsSelected([])
            setChecked([]);
        } else {
            setChecked(lastUserLocationsInView.map((lastUserLocation: LastUserLocation) => lastUserLocation.id));
            setLastUserLocationsSelected(lastUserLocationsInView);
        }
    }

    const onCloseNotification = () => {
        setOpenCreateNotification(false);
    }

    const marks = [
        {value: 1000, label: '1km'},
        {value: 100000, label: '100km'},
        {value: 200000, label: '200km'},
        {value: 500000, label: '500km'}
    ];

    const valueTextForSlider = (value: number) => {
        return `${Math.round(value / 1000)}km`;
    }

    const onCreateNotification = async (notification: DocumentData) => {
        setOpenCreateNotification(false);
        setIsLoading(true);
        try {
            const notificationDTO: NotificationDTO = {
                users: notification.users,
                notification: {
                    title: {
                        de: notification.title_DE,
                        fr: notification.title_FR,
                        it: notification.title_IT
                    },
                    body: {
                        de: notification.content_DE,
                        fr: notification.content_FR,
                        it: notification.content_IT
                    }
                }
            }

            const result = await createNotification(notificationDTO);

            setResultResponse(result.data.message);
        } catch (e: any) {
            setError(e.message);
        }
        setIsLoading(false);
    };

    const handleChangeHoursLastUserLocation = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const hours = parseInt(event.target.value);
        setAgeSearch(hours);
    };

    const handleBackToList = () => {
        onDeleteCircle();
        setDrawCircle(false);
    }

    return <>
        {isLoading &&
            <DialogLoader loading={isLoading} onClose={() => setIsLoading(!isLoading)}/>
        }
        {error &&
            <ErrorResponseDialog errorCode={error} onClose={() => {
                setError(undefined);
            }}/>
        }
        {resultResponse &&
            <DialogNotificationResponse message={resultResponse} onClose={() => setResultResponse(undefined)}/>
        }
        {openCreateNotification &&
            <DialogNewNotification open={openCreateNotification} onCreate={onCreateNotification}
                                   handleClose={onCloseNotification} lastUserLocationsSelected={lastUserLocationsSelected}/>
        }
        <Stack direction='column' className={"tools-column"} height={"100%"}>
            <Admin/>
            <Divider/>
            <Stack padding={1}>
                {
                    drawCircle ?
                        <Stack direction='column' gap={1}>
                            <Stack direction='row' sx={{alignItems: "center"}}>
                                <Button
                                    sx={{
                                        minWidth: "auto",
                                        color: "black"
                                    }}
                                    onClick={handleBackToList}>
                                    <ArrowBackIcon/>
                                </Button>
                                <Typography variant={"h5"}>
                                    {t("button_label_back_to_list")}
                                </Typography>
                            </Stack>
                            <Typography variant={"body1"}>
                                {t("draw_circle_text")}
                            </Typography>
                            <Stack direction='column' paddingLeft={1} paddingRight={4}>
                                <Slider
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            color: "#005aa0"
                                        },
                                        '& .MuiSlider-thumb.Mui-focusVisible': {
                                            color: "#005aa0"
                                        },
                                        '& .MuiSlider-track': {
                                            color: "#005aa0"
                                        },
                                        '& .MuiSlider-rail': {
                                            color: "#005aa0"
                                        },
                                        '& .MuiSlider-markLabel': {
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }
                                    }}
                                    step={10}
                                    marks={marks}
                                    min={MIN_RADIUS_SLIDER}
                                    max={MAX_RADIUS_SLIDER}
                                    value={circleRadius || DEFAULT_RADIUS_SLIDER}
                                    valueLabelDisplay="on"
                                    onChange={changeCircleRadiusValue}
                                    valueLabelFormat={valueTextForSlider}
                                />
                            </Stack>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setDrawCircle(false);
                                }}>
                                <DoneIcon/> {t("button_confirm_label")}
                            </Button>
                        </Stack> :
                        <>
                            <Typography variant={"h4"}>
                                {t("last_user_locations_title")}
                            </Typography>
                            <Typography variant={"subtitle2"}>
                                {t("last_user_locations_subtitle")}
                            </Typography>
                            <Stack gap={2} mt={1}>
                                {circleCenter && circleRadius &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={onDeleteCircle}>
                                        <ClearIcon/> {t("button_label_clear_circle")}
                                    </Button>
                                }
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={onDrawCircle}>
                                    <ModeIcon/> {(circleCenter && circleRadius) ? t("button_label_edit_circle") : t("button_label_draw_circle")}
                                </Button>
                                <SearchBar/>
                                <AgeFilter ageSearch={ageSearch} handleChangeHours={handleChangeHoursLastUserLocation}/>
                            </Stack>

                            <LastUserLocationListAdapter lastUserLocations={lastUserLocationsInView} checked={checked}
                                                  handleToggle={handleToggle}/>
                            {lastUserLocationsInView.length > 0 &&
                                <Stack gap={2} mt={1}>
                                    <Typography variant={"body1"}>
                                        {checked.length.toString()
                                            + "/"
                                            + lastUserLocationsInView.length.toString()
                                            + " "
                                            + t("last_user_locations_selected")}
                                    </Typography>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={onSelectOrDeselectAll}>
                                        <ChecklistIcon/> {t("button_label_select_deselect")}
                                    </Button>
                                </Stack>
                            }
                            {checked.length > 0 &&
                                <>
                                    <Divider sx={{mt: 1}}/>
                                    <Stack direction='column' sx={{justifyContent: "center", mt: 1}}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => setOpenCreateNotification(true)}>
                                            <SendIcon/> {t("button_label_create_notification")}
                                        </Button>
                                    </Stack>
                                </>
                            }
                        </>
                }
            </Stack>
            <Menu/>
        </Stack>
    </>
}
